html {
  height: 100%;
}

body {
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  padding-top: 65px;
}

#root {
  height: 100%;
}
.card-table table,
.card-table tbody,
.card-table tr {
  display: block;
}

 {
  display: block;
}

.card-table td,
.card-table th {
  padding: 8px 12px;
  display: inline-block;
  width: 50%;
  overflow: scroll;
  text-overflow: clip;
  white-space: nowrap;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.card-table td::-webkit-scrollbar,
.card-table th::-webkit-scrollbar {
  display: none;
}
